/**
 * Mi Guatemala SASS
 * Author: RedCiudadana
 * repository: https://github.com/RedCiudadana/MiGuatemala
 */

/**
 * Tabla de contenidos
 *
 * Colors utils
 * Navbar
 * Footer
 * Perfil
 *
 * - Components
 * Portfolio
 *
 *  - Utils
 * Font awesome
 * Links
 * Panel
 */

// Colors utils
$fb-color: #3B5998;
$tw-color: #1DA1F2;

@font-face {
    font-family: 'HindSiliguri';
    src: url(http://www.candidatos.gt/fonts//HindSiliguri-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'HindSiliguri';
    src: url(http://www.candidatos.gt/fonts//HindSiliguri-Bold.ttf) format('truetype');
    font-weight: bold;
}

@font-face {
    font-family: 'HindSiliguri';
    src: url(http://www.candidatos.gt/fonts//HindSiliguri-Light.ttf) format('truetype');
    font-weight: lighter;
}

@font-face {
    font-family: 'Roboto';
    src: url(http://www.candidatos.gt/fonts//Roboto-Regular.ttf) format('truetype');
}

// Top banner
.top-banner {
    padding-left: 80px;
    padding-right: 80px;
}

// Navbar

.navbar {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);

    .navbar-brand img {
        height: 65px;
        margin-left: 50px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    // Button style
    ul li a {
        color: $navbar-color;
        // opacity: 0.8;
        font-size: 1.2rem;
    }

    // Social links - facebook - twitter
    .social {
        padding: 8px;
        border-radius: 25px;
    }

    .fb:hover {
        background-color: $fb-color;
    }

    .tw:hover {
        background-color: $tw-color;
    }
}

// Footer

#footer {
    background-color: $footer-background-color;
    color: $footer-color;
    padding-top: 40px;
    padding-bottom: 40px;

    a {
        color: $footer-color;
    }

    a:hover {
        // Calcula el color según el color de fondo. Aclara.
        color: lighten( $footer-background-color, 30% ) !important;
    }

    i {
        margin-right: 8px;
    }

    // Ajusta alineación con los demas iconos.
    .fa-map-marker {
        padding-left: 2px;
    }

    .follow {
        h6 {
            margin-bottom: 1.5rem;
        }

        img {
            margin-right: 10px;
        }
    }

    .image-opacity:hover {
        opacity: 0.8;
    }
}

#red-footer {
    background-color: #e6e7e8;
    color: #404041;
    padding-top: 40px;
    padding-bottom: 40px;
    font-family: 'Roboto' !important;

    .logo-rc {
        width: 120px;
        height: 62px;
    }

    .cc-icons img {
        margin-right: 10px;
    }
}

@include media-breakpoint-up(md) {
    #red-footer {
        .logo-rc {
            margin-top: 13px !important;
        }
    }
}

// Perfil

.nav-candidator {
    background-color: $light;
    color: $primary;

    ul li {
        padding: 15px;
    }

    ul li:hover, li:hover a {
        background: $secondary;
        // Calcula el color según el color de fondo. Aclara 80%.
        color: lighten( $secondary, 80% ) !important;
    }
}

.profile {

    .row {
        padding: 1rem;
    }

    img#photo-profile {
        max-width: 350px;
        max-height: 250px;
        // margin-top: 25px;
        // margin-bottom: 25px;
        // width: 80%;
    }
}

@include media-breakpoint-up(md) {
    .profile .row {
        div + div div.panel {
            margin-left: 0.5px;
        }
    }
}

/**
 * Components
 */


// Portfolio

.portfolio {

    .item {
    width: 100%;
    max-width: 400px;
    padding: 10px;
    // Ademas centra la imagen
    text-align: center;
    // Centrar item
    margin: auto;

        .background {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        .image img {
            width: 80%;
        }

        .text {
            padding-top: 10px;
            // Un 'height' determinado para que no varie el alto del item según lo largo del nombre, etc, entre los items.
            height: 80px !important;
            line-height: 1 !important;
        }
    }
}

@include media-breakpoint-up(sm) {
    .portfolio .item {
        width: 50%;
    }

    .portfolio .item {
        float: left;
        position: relative;
        height: auto;
    }

    .portfolio .item .image {
        // Un 'height' determinado para que no varie el alto del item según el ratio de la imagenes entre los items.
        height: 200px;
        position: relative;
        overflow: hidden;
    }
}

@include media-breakpoint-up(md) {
    .portfolio .item {
        width: 33.33%;
    }
}

@include media-breakpoint-up(lg) {
    .portfolio .item {
        width: 20%;
    }
}

/**
 * Utils
 */

// Links
a, a:hover {
    text-decoration: none;
}

a:hover {
    // Calcula el color según el color primario. Aclara.
    color: lighten( $primary, 10% ) !important;
}

// Font awesome
i {
    margin-left: 3px;
    margin-right: 3px;
}

// Panel
.panel {
    @extend .border;
    margin-top: 15px;
    margin-bottom: 15px;
    border-radius: $border-radius;
    overflow: hidden;
    // Eliminar el padding de col-*
    margin-left: -15px;
    margin-right: -15px;

    .header {
        padding: 15px;
        background-color: $primary;
        color: lighten( $primary, 80% ) !important;
        border: 1px 0 0 0;

        img {
            height: 40px;
            width: auto;
            margin-left: 10px;
            margin-right: 10px;
        }

        h1, h2, h3, h4, h5, h6 {
            // Centrar vertical
            margin-top: auto;
            margin-bottom: auto;
        }
    }

    .body {
        padding: 35px;
    }
}
